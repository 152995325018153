import React from "react";
import styled from 'styled-components';

import './css/landing.css';
 const Button = styled.button`
 background: ${props => props.primary ? " #c8aa58" : "#002FAA"} ;
 color: white;
 font-size: 16px;
 margin: 1em;
 padding: 0.25em 1em;
 width: 100px;
 border: ${props => props.primary ? "2px solid #c8aa58" : " 2px solid #002FAA"} ; 
 /* border-radius: 3px; */
`;

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: #002FAA;
  font-family:Lora;
`;
const StyledLink = styled.a`
  text-decoration: none;
`;
const Thing = styled.div`
  && {
    padding-top:30px;
    font-family: 'Open Sans', sans-serif;
  }
`;

const Input = styled.input.attrs(props => ({
    // we can define static props
    type: "email",
  
    // or we can define dynamic ones
    size: props.size || "1em",
  }))`
    /* color: gold; */
    font-size: 1em;
    border: 2px solid #002FAA;
    border-radius: 3px;
  
    /* here we use the dynamically computed prop */
    margin: ${props => props.size};
    padding: ${props => props.size};
  `;
 class User extends React.Component{
     constructor(props){
         super(props);
         this.state={
             
             email:'dummy@email.com',
             ip:'192.168.1.28'

         }
     }
     async componentDidMount(){
        console.log("quiz platform reached");
        const response = await fetch('https://api.ipify.org/?format=json');
        const json = await response.json();
        console.log("fetch json",json);
        this.setState({ ip: json.ip });
     }
     validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
     createUser =()=>{
         
        let hist= this.props.history; 
        let email=this.state.email;
        console.log("trace mail",email);
        let v= this.validateEmail(email);
        if(!v){ 
            alert (`Your email ${email} is not a valid email.`);
        } 
        else if(email ==='' || email ===""){
          alert (`Your email ${email} is not a valid email.`);
        }
        else{
            hist.push({
                pathname:`/quiz`,
                query: email
              });
        }
     }
     skipSign = () =>{
       
        let hist= this.props.history; 
        hist.push({
          pathname:`/quiz`,
          query: this.state.ip
        });
        
     }
     handleChange =(e) =>{
         let n = e.target.value;
        //  console.log("Email",n);
         this.setState({email:n});
     }
     render(){
         return(
             <div className="landing">
                <div className="landing-logo">
                  
                <StyledLink href=' https://houseoftheta.com/'rel="noopener noreferrer"  target="_blank">
                    <img id="logophoto" src={"https://i.ibb.co/B38wmWD/thetagold-Cropped.png"} alt="House of Theta" />
                </StyledLink>
                </div>
                <div className='landing-content'>

                <Title>Discover your seasonal style and the imbalances that inhibit your natural flow</Title>
                <Thing>
                We are all born with certain characteristics and tendencies which inform our approach to life and shape the way it unfolds.
                  When in balance these tendencies, they can be harnessed to help flow through life with a personal style and grace.
                  These individual strengths can carry us forward with ease and clarity in creating our life’s great work.
                  But these tendencies can also be knocked out of balance by life’s various challenges and trap us in certain habits or stages which inhibit our natural flow and creative engagement through life.
                  A winter person might be deeply reflective, insightful and stand out in their ability to bring authenticity and truth to their work. 
                  But when stressed or challenged that same reflective tendency could manifest as apprehension or an inwards retreat which causes fatigue ordepression.
                  Take this short quiz to begin exploring your seasonal style and any imbalances that hold you back.
                </Thing>
                 <Input onChange={this.handleChange} placeholder="Enter your Email here" />
                 <br></br>
                 <Button primary onClick={this.skipSign}>Skip</Button>
                 <Button onClick={this.createUser}>Submit</Button>
                </div>
             </div>
         )
     }
 }
 export default User