import React, { Component } from 'react';

import * as Survey from "survey-react";

import './App.css';
import 'survey-react/survey.css';
import './css/myversion.css';

import { calculateAnswer,getQuizQuestions } from './services/ClientServices';
//[questions]
//quesion ={title:question text,elements(answers),page=id,}
//elements.name = answer text
var card = {
    "pages": [
        {
            "name": "pagea0",
            "title": "The question text",
            "elements": [
                {
                    "type": "radiogroup",
                    "title": "The answer text",
                    "descriptionLocation": "underInput",
                    "titleLocation": "top",
                    "choices": [
                        {
                            value: 20,
                            text: "Extremly does not resonate for me"
                          }, {
                            value: 40,
                            text: "Does not resonate for me"
                          }, {
                            value: 60,
                            text: "Neutral"
                          }, {
                            value: 80,
                            text: "Resonate for me"
                          }, {
                            value: 100,
                            text: "Highly resonate for me"
                          }
                    ],
                    "colCount": 5
                }
            ],
            "questionTitleLocation": "top",
            "questionsOrder": "initial"
        },
       
    ],
    "questionErrorLocation": "bottom",
    "showProgressBar": "top",
    "progressBarType": "questions",
    "goNextPageAutomatic": true,
    // "questionsOnPageMode": "singlePage"
}
class QCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isComplete: false,
            email: "",
            questions: [],
            fin: "Try again",
            winter: 0,
            spring: 0,
            fall: 0,
            mid: 0,
            late: 0,
            mobile:5,
            lowest:'',
            low:'',
            midly:'',
            high:'',
            highest:''
        }
        this.onCompleteComponent = this.onCompleteComponent.bind(this);
    }
    componentDidMount() {
        this.setState({email:this.props.location.query});
        console.log("styletab tab",this.props.location.query);
        if(window.innerWidth <450){
          console.log("mobileee");
          this.setState({mobile:1});
        }
        getQuizQuestions().then((response) => {
            if (response.data.err) {
              console.log("err in response")
              console.log(response.data.err);
            } 
            else {
              //call function to insert models into data source
              //exit command
              console.log('response is cool ',response.data);
              this.setScale(response.data.scale);
              this.mapCallToState( response.data.questions);
            }
          })
          .catch((err) => {
            console.log("Catch an eror in geting questions",err)
          });
      
    }
    // setIp = (e) =>{
    //   if(e !== 'dumb'){
    //     this.setState({email:e});
    //   }
    //   else{

    //   let myip='';
    //   let outSideThis=this;
    //   fetch('https://api.ipify.org/?format=json').then(res => res.json()).then(
    //     function(data){
    //       myip=data.ip;
    //       outSideThis.setState({email:myip});
    //     }
    //    );
    //   }
    // }
    setScale =(obj) =>{
      console.log("setting scale",obj);
      let hi=obj.h;
      let hhigh=obj.hh;
      let mi= obj.m;
      let llow = obj.l;
      let lo=obj.lh;
      this.setState({low:lo,high:hi,highest:hhigh,midly:mi,lowest:llow})
    }
    mapCallToState = (obj) =>{
      console.log("Mapping response to quiz",obj);
        //question text = page.title;
        //answer text = elements[i].title;
        //create a counter to take the name position
        //fixed col = elements.choices
        let fixedcol= [
          {
            value: 20,
            text: this.state.lowest
          }, {
            value: 40,
            text: this.state.low
          }, {
            value: 60,
            text: this.state.midly
          }, {
            value: 80,
            text: this.state.high
          }, {
            value: 100,
            text: this.state.highest
          }
        ];
        //we populate pages array in our questions const with qinsts
        
        let ansinst={
            "type": "radiogroup",
            "name": "Question1w=7i=0",
            "title":"The answer text",
            "descriptionLocation": "underInput",
            "titleLocation": "top",
            "choices":fixedcol,
            "colCount": this.state.mobile
        }
        let qinst = {
            "name": "1page",
            "elements": [],
            "title": "The question text",
        }
        //the obj is the api call response 
        //the keys are the questions' ids
        // let qids =Object.keys(obj);
        //the 0 shall be i and in a for loop
        
        // let questionslist= obj[qids[0]];
        let questionslist= obj;
        // console.log("das list",questionslist);
    
        let qlen= questionslist.length;
        console.log("qlen",qlen);
        
        for (var i=0;i<qlen;i++){
          let question = questionslist[i];
          // console.log("reached question",question);
          let qstat=question.status ||0;
    
          let qname= question.text;
          let ans =question.answers;
          // console.log("My answers",ans);
          //ans[0].text => answer text
          //ans[0].weight => answer weight
    
          //page name
          qinst.name='page '+i;
          //question text
          qinst.title=qname;
    
          //for loop to add answers
          for (var p=0;p<ans.length;p++){
            ansinst.title = ans[p].text;
            ansinst.name = "Question" +String(i +1) +'w='+ ans[p].weight +'i='+String(qstat);
            //before finally 
            qinst.elements.push(ansinst);
            ansinst={
              "type": "radiogroup",
              "name": "The answer weight",
              "title":"The answer text",
              "descriptionLocation": "underInput",
              "titleLocation": "top",
              "choices":fixedcol,
              "colCount": this.state.mobile
            }
          }
          //finally
          card.pages.push(qinst);
          qinst={
            "name": "page2",
            "elements": [],
            "title": "The question text",
          }
        }
        console.log("my created qqeustions before",card);
       
        card.pages.shift();
     
        this.setState({isLoading:false});
        console.log("my created qqeustions after",card);
      }
    onSurveyFinish(){
        if(this.state.isComplete){
          return(
              <div> <h3>You are done thank you</h3></div>
              
          )
        }
       
  }
  onCompleteComponent = (survey, options) =>{
      
    console.log("Check Complete survey" );
    
    let hist= this.props.history;
    let progstr=  survey.progressText;
    let strno = progstr[9];
    let sd =this.props.location.query;
    if(strno ==='0'){
      alert('Please answer the quiz before complete');
      hist.push({
        pathname:`/`,
        query: sd
      });
    }
    else{
      console.log("Quiz Ended",survey.data);
      let score=undefined;
      let imbalance= undefined;
      // console.log("io",options);
      //make an is loading state with a call back to set is complete to true
      calculateAnswer(survey.data,sd).then((response) => {
        if (response.data.err) {
          console.log("err in response")
        } 
        else {
          //call function to insert models into data source
          //exit command
          console.log(' Calculate response is cool ',response.data);
          score= response.data.score;
          imbalance =response.data.imb;
          hist.push({
            pathname:`/output`,
            query:  [score,imbalance],
          });
          
        }
      })
      .catch((err) => {
        console.log("Catch in submit answers",err);
      });
      this.setState({isComplete:true});
      
    }
  
  }
   
    render() {

        return (
            <div className="hotquiz" >
                {/* <img src={logo} /> */}
                {/* <h1> Style</h1> */}
                <div>
                    <a href='/' rel="noopener noreferrer">
                    <img id="logophoto" src={"https://i.ibb.co/B38wmWD/thetagold-Cropped.png"} alt="House of Theta" />
                    </a>
                </div>
                {/* consider adding an h6 here to show progress text */}
                <div>
                    {!this.state.isComplete && (!this.state.isLoading) ?
                        <Survey.Survey
                            // json={onlinequestion}
                            json={card}
                            showCompletePage={false}
                            onComplete={this.onCompleteComponent}
                        />
                        :
                        <div style={{ textAlign: 'center' }} >
                            <h2>Loading...</h2>
                        </div>}
                    {this.onSurveyFinish()}
                </div>
            </div>
        );
    }
}

export default QCard;
