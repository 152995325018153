import React from "react";

// import App from './App';
// import Qstatic from './Qstatic';
import Pie from './Pie';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import QCard from "./QuizCard";
import User from './User';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      uid:0,
      messages:[]
    };
  }

  componentDidMount() {
  
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>

            <Route path="/" exact component={User} />
            
            <Route path="/quiz" exact component={QCard} />
            <Route path="/output" exact component={Pie} />
            <Route path="/" render={()=> <div>404</div>} />
            {/* <Route path="/quiz" component={App} /> */}
            
            {/* <Route path="/style" component={QCard} /> */}
            {/* <Route path="/result/:id" component={Result} /> */}
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default Loader;
