import axios from 'axios';

// const apiUrl = 'https://house-of-theta-quiz.herokuapp.com/';

const apiUrl = 'https://house-project-2882.nw.r.appspot.com';
// const apiUrl = 'http://localhost:5000';


export function getQuestions() {
    //gets all questions 
    return axios.get(`${apiUrl}/questions/all`);
}
export function getActiveQuiz() {
    //gets all questions 
    return axios.get(`${apiUrl}/games/current`);
}

export function getQuizQuestions() {
    //gets all questions 
    return axios.get(`${apiUrl}/games/qquestions`);
}

export function submitAnswer( object) {
    //returns user personality
    return axios.post(`${apiUrl}/questions/answers`, {'anser':object});
}
export function calculateAnswer(object ,em) {
    //returns user personality
    return axios.post(`${apiUrl}/questions/ranswers`, {'anser':object ,'email':em});
}
//message api
export function getMessages() {
    return axios.get(`${apiUrl}/games/msgs`);
}