import React,{Component} from 'react';
import {Doughnut,Bar} from 'react-chartjs-2';
//data.data shall be an array of the precentages rounded to integers
import styled from 'styled-components'
import {getMessages} from './services/ClientServices';
// import {messages} from './constants/outputs';

import './css/landing.css';
//end of imports

const myoptions = { 
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          ticks: {
            max: 100,
            min: 0
          },
          gridLines: {
            display: false
          },
          labels: {
            show: true
          }
        }
      ]
    }
  };
const StyledLink = styled.a`
  color: #002FAB;
  text-decoration: none;
  border: 1px solid transparent;
  &:hover { border-color: rgba(108, 48, 176, 0.5); }
  &.selected { border-color: rgba(108, 48, 176, 0.5); }
`;
const Button = styled.a`
  background: ${props => props.primary ? "#c8aa58" : "white"};
  color: ${props => props.primary ? "white" : "#c8aa58"};
  border: 2px solid #c8aa55;
  
  font-size: ${props => props.sc === 1.0 ? "1em" : "0.95em"};
  /* margin: 1em;
  margin-bottom:25px; */
  padding: 0.25em 1em;
  
  &:focus {
    border: none;
  }
  &:active {
    border: none;
  }
  &:visited {
      border:none;
  }

  /* border-radius: 3px; */
`;
const Title = styled.pre`
  font-size: 1.5em;
  text-align: center;
  color: #002FAA;
  font-family: Lora;
  white-space: pre-line;
`;

const Thing = styled.div`
  && {
    color: #002FAB;
    padding:15px;
    padding-top:30px;
    padding-bottom:30px;
    font-family: 'Open Sans', sans-serif;
  }
`;

const Imtitle = styled.h3`
  text-align: left;
  color: #002FAA;
  font-weight: 600;
  font-size: 18px;
  font-family: Lora;
`;

const Imtxt = styled.div`
  && {
    color: #002FAB;
    font-size: 14px;
    text-align: ${props => props.primary ? "center" : "left"};
    font-family: 'Open Sans', sans-serif;
  }
`;
const Gold = styled.div`
  && {
    color: #A78A45;
    padding:15px;
    font-size: 14px;
    font-weight: ${props => props.primary ? 600 : 700};
    font-size: ${props => props.primary ? "14px" : "24px"};
    font-family: 'Open Sans', sans-serif;
    font-family: ${props => props.primary ? "'Open Sans', sans-serif" : "Lora"};
  }
`;



const piedata={
    labels: [
        'Winter',
        'Spring',
        'Summer',
        'Transitions',
        'Autumn',
    ],
    datasets: [{
        data: [20,20,20,20,20],
        backgroundColor: [
            '#002FA7',
            '#A88B46',
            '#5a6abf',
            '#110f58 ',
            '#E1DEC2',
        
        ],
        hoverBackgroundColor: '#472771'
        
    }]
};

const impiedata={
    labels: [
        'Winter Imbalance',
        'Spring Imbalance',
        'Mid Summer Imbalance',
        'Late Summer Imbalance',
        'Autumn Imbalance',
    ],
    datasets: [{
        data: [20,20,20,20,20],
        backgroundColor: [
            
            '#002FA7',
            '#A88B46',
            '#5a6abf',
            '#110f58 ',
            '#E1DEC2',
        
        ],
        hoverBackgroundColor: '#472771'
        
    }]
}
class Pie extends Component{
    constructor(props){
        super(props);
        this.state={
            toggle:true,
            messages:[],
            isMobile:1.0,
            headt:'Header Text',
            headi:'Imbalance Header',
            message:'Message Text',
            imsg:'Imbalance Message',
            imdata:{
                label: 'Imbalances',
                datasets: [{
                    data: [20,20,20,20,20],
                    backgroundColor: [
                        '#002FA7',
                        '#A88B46',
                        '#5a6abf',
                        '#110f58 ',
                        '#E1DEC2',
                    
                    ],
                    hoverBackgroundColor: '#472771'
                    
                }]
            },
            pdata :{
                labels: [
                    'Winter',
                    'Spring',
                    'Summer',
                    'Transitions',
                    'Autumn',
                ],
                datasets: [{
                    data: [20,20,20,20,20],
                    backgroundColor: [
                        '#002FA7',
                        '#A88B46',
                        '#5a6abf',
                        '#110f58 ',
                        '#E1DEC2',
                    
                    ],
                    hoverBackgroundColor: '#472771'
                    
                }]
            },
            empty:{
                "display":false
            },
            legend:{
                "display": true,
                "position": "top",
                "fullWidth": true,
                "reverse": false,
                onClick:this.legendOpts,
                "labels": {
                    "fontColor": 'rgb(255, 99, 132)'
                  }
              },
        }
    }
    componentDidMount(){
        if(window.innerWidth <863){
            console.log("mobileee",window.innerWidth);
            let scale= window.innerWidth / 863;
            console.log(scale);
            this.setState({isMobile:scale});
          }
        console.log('Piechart');
        let query=this.props.location.query;
        console.log("query", query);
        if(query !== undefined && (query !==[])){
            this.updatePie(query[0]);
            this.updateImbalance(query[1]);
        
        getMessages().then((response) => {
            let rd= response.data;
            if (response.data.err) {
                console.log("err in response")
                console.log(response.data.err);
            } 
            else {
                console.log('response is cool ',rd);
                console.log("State obj",rd.messages);
                this.setState({messages:rd.messages}, () =>this.toggleText(this.state.pdata));
            }
              
        })
        .catch((err) => {
            console.log("Catch an eror in geting messages",err)
          });
        }
        else{
            console.log("component is not ready ");
            let hist= this.props.history;
            hist.push({
                pathname:`/`
              });
        }
    }

    updatePie(q){
        //fill array with values from query
        console.log("order arrive",q);
        let array=[];
        // this.setState({score:array});
        array.push(q['winter']);
        array.push(q['spring']);
        array.push(q['mid']);
        array.push(q['late']);
        array.push(q['fall']);
        console.log("coming core array",array);
        piedata.datasets[0].data =array;
        console.log(this.state.pdata);
        this.setState({pdata:piedata});

    }

    updateImbalance(q){
        let array=[];
        array.push(q['winter']);
        array.push(q['spring']);
        array.push(q['mid']);
        array.push(q['late']);
        array.push(q['fall']);
        console.log("coming imb array",array);
        impiedata.datasets[0].data =array;
        console.log("imbalance update",this.state.imdata);
        this.setState({imdata:impiedata});
    }

    pdClick =(elems )=>{
        console.log(elems);
        if(elems ===undefined || elems ===[] ||elems.length <1){
            console.log("5las");
        }
        else{
            let ind=elems['0']._index;
            console.log("doughnot ",ind);
            let cs=this.state.pdata.datasets[0].data[ind];
            alert (`Component score is ${cs} %`);
        }
    }

    legendOpts = (e,item) => {
        //e is defined but not used to avoid misconseption between event and item 
        // console.log(item);
        alert(`You Should be redirected to the ${item.text} explanation`)
    }

    handleToggle = (e) =>{
        // console.log("Switch toggle",e);
        let val=e;
        if(val ==='c'){
            //core
            this.setState({toggle:true});
        }
        else{
            //Imbalance
            this.setState({toggle:false});
        }
    }

    toggleText(q){
        console.log("My messages",this.state.messages);
        // let k = Object.keys(q);
        let arrq = Object.values(q);
        console.log("toggling text", arrq);
        let arr =arrq[1][0].data;
        //2nd place 
        var prevMax = -1;
        var maxIndex = 0;
        var max = arr[0];
        for (var i = 1; i < arr.length; i++) {
            if (arr[i] > max) {
                prevMax =maxIndex +0;
                maxIndex = i;
                max = arr[i];
            }
            else if(arr[i]>arr[prevMax] && arr[i]<=arr[maxIndex]){
                prevMax=i;
            }
            // if(arr[i] === arr[maxIndex]){
            //     prevMax=i;
            // }
        }
        console.log("PeviousMax",prevMax);
        //this if purpose to check whether 2 headers are displayed or 1 
        if( (arr[maxIndex]-arr[prevMax] >= 15) || prevMax === maxIndex){   
            console.log("Solo season");
            prevMax=-1;
        }
        else{
            console.log()    
            console.log("The score difference",arr[maxIndex] - arr[prevMax]);
        }
        
        console.log("The prev max",prevMax);
        console.log("Max ",maxIndex)
       let mymsg= this.state.messages;
       let defcat="";
       //switch case to determine message
       let correctindex=0;
       switch (true) {
        case (prevMax=== -1 && maxIndex === 0):
            // Winter pure (Audited = correct)
            //set defcat here
            defcat="Winter";
            break;
        case (prevMax=== -1 && maxIndex === 1):
            // Spring pure (Audited = correct)
            defcat="Spring";
            break;
        case (prevMax=== -1 && maxIndex === 2):
            // Summer pure (Audited = correct)
            defcat="Mid";
            break;
        case (prevMax=== -1 && maxIndex === 3):
            // Late pure 
            defcat="Late";
            break;
        case (prevMax=== -1 && maxIndex === 4):
            //Autumn pure
            defcat="Fall";
            break;

        //combined when there is a 3% difference
        case (prevMax=== 1 && maxIndex === 0):
        case (prevMax=== 0 && maxIndex === 1):
            // Winter Spring (Audited = correct)
            defcat="Wspring";
            break;
            
        case (prevMax=== 1 && maxIndex === 2):
        case (prevMax=== 2 && maxIndex === 1):
            // Summer Spring (Audited = correct)
            defcat="Smid";
            break;
        case (prevMax=== 1 && maxIndex === 3):
        case (prevMax=== 3 && maxIndex === 1):
            //Spring late (Audited = correct)
            defcat="Slate";
            break;
        case (prevMax=== 1 && maxIndex === 4):
        case (prevMax=== 4 && maxIndex === 1):
            // Spring Autumn (Audited = correct)
            defcat="Sfall";
            break;

        case (prevMax=== 2 && maxIndex === 0):
        case (prevMax=== 0 && maxIndex === 2):
            // Winter Summer (Audited = correct)
            defcat="Wmid";
            break;
        case (prevMax=== 2 && maxIndex === 3):
        case (prevMax=== 3 && maxIndex === 2):
            // Late Summer (Audited = correct)
            
            defcat="Mlate";
            break;
        case (prevMax=== 2 && maxIndex === 4):
        case (prevMax=== 4 && maxIndex === 2):
            // Fall Summer (Audited = correct)
            defcat="Mfall";
            break;

        case (prevMax=== 3 && maxIndex === 0):
        case (prevMax=== 0 && maxIndex === 3):
            // Winter late (Audited = correct)
            defcat="Wlate";
            break;
            
        case (prevMax=== 4 && maxIndex === 3):
        case (prevMax=== 3 && maxIndex === 4):
            // Autumn Late (not in db )(Audited = correct)
            
            defcat="Lfall";
            break;

        case (prevMax=== 4 && maxIndex === 0):
        case (prevMax=== 0 && maxIndex === 4):
            // Winter Autumn (not in db )(Audited = correct)
            
            defcat="Wfall";
            break;
         
        default:
            correctindex=0;
    }
    console.log("DefCat",defcat);
    for(var j=0;j<mymsg.length;j++){
        console.log(mymsg[j].category);
        if(mymsg[j].category === defcat){
            correctindex =j;
        }
    }
    console.log('tracing',correctindex);
    //    let cho= k[maxIndex];
    //    let icho = k[prevMax];
       //core message
       let msg= this.state.messages[correctindex].text;
       let dynamicheader= this.state.messages[correctindex].title;
       this.setState({headt:dynamicheader});
       //imbalance message 
    //    let imsg = this.state.messages[prevMax].text;
    //    this.setState({imsg:imsg});

       this.setState({message:msg});
    }
    
    render(){
        
        return(
            
            <div>
                <div className="landing-logo">
                <StyledLink href=' https://houseoftheta.com/'rel="noopener noreferrer"  target="_blank">
                   
                    <img id="logophoto" src={"https://i.ibb.co/B38wmWD/thetagold-Cropped.png"} alt="House of Theta" />
                  
                </StyledLink>
                </div>
            <Title>Quiz Output</Title>
                <div className='landing-content'> 
                    <div className='buttonstoggle'>
                    {this.state.toggle ?
                    <div>
                        <Button sc={this.state.scale} onClick={() => this.handleToggle('c')}  primary>Your seasonal archetypes</Button>
                        <Button sc={this.state.scale} onClick={() => this.handleToggle('e')} >Blockages and imbalances</Button>
                    </div>:
                    <div>
                        <Button sc={this.state.scale} onClick={() => this.handleToggle('c')}  >Your seasonal archetypes</Button>
                        <Button sc={this.state.scale} onClick={() => this.handleToggle('e')}  primary>Blockages and imbalances</Button>
                    </div>
                        }
                    </div>
                {this.state.toggle ?
                    <div >
                        <Doughnut data={this.state.pdata}
                        onElementsClick={this.pdClick} legend={this.state.legend} 
                        width={700}
                        height={350}
                        options={{ maintainAspectRatio: false }}/>
                    </div>
                    :
                    <div>
                    <Bar data={this.state.imdata}
                        legend={this.state.empty}
                        width={700}
                        height={350}
                        options={myoptions}/>
                    
                    </div>
                 
                }
                </div>

                <div className="omsg" style={{padding:50}}>
                {this.state.toggle ? 
                <div>
                    <Title>

                        {this.state.headt}
                    </Title>
                    <Thing>
                        {this.state.message}
                    </Thing>
                    <Gold primary>
                    If you would like to understand the ways in which you have lost your harmony and work to restore
                    balance then please do look through our free &nbsp;
                     <StyledLink href='https://houseoftheta.com/online-resources/' rel="noopener noreferrer" target="_blank">
                         online resources</StyledLink>, check our up and coming events or get in touch for some &nbsp;
                      <StyledLink href='https://houseoftheta.com/contact/'rel="noopener noreferrer"  target="_blank">
                           1-2-1 coaching</StyledLink> to help navigate your personal journey.  
                    </Gold>

                </div>
                        :
                <div>
                    <Gold> BRINGING BACK HARMONY </Gold>
                    <Imtxt primary> 
                    Our characteristics and tendencies can cause us to create certain imbalances in our lives that
                    disrupt our harmony or creative flow.
                    We can become addicted to some behaviours or fearful of others. Our life stage and external
                    events can also impact the  season that we find ourselves in.
                    They can be behavioural imbalances but also emotional, mental, spiritual or physiological imbalances.
                    We can become overactive in some ways of being and underactive in others; each in their own way
                    limiting us from living to our fullest potential.
                    But when we see ourselves as a whole carefully balanced entity,
                    who’d natural way of being is to move with ese through all of the different seasons,
                    we can identify the areas get trapped and where we bring in more harmony to our lives so that
                    we might begin to flourish.
                    Every fear is paired with a desire.
                    Every period of growth is balanced with a time to grief.
                    There are times to broaden our horizons and times to narrow our focus.
                    Notice the seasons which appear to be out of balance for you right now and consider how you
                    might bring back harmony.
                    </Imtxt>
                    <Imtitle> Imbalanced Winter</Imtitle>
                    <Imtxt>
                    Offset toughness or detachment with tenderness, sensitivity and openness.
                    </Imtxt>
                    <Imtitle> Imbalanced Spring</Imtitle>
                    <Imtxt>
                    Modulate intensity by staying flexible and willing to yield or retreat as opposed to pushing forward.
                    </Imtxt>
                    <Imtitle> Imbalanced Summer</Imtitle>
                    <Imtxt>
                    Temper your firey chemistry by conserving or sharing out your energy
                    – either taking some time to withdraw or to embrace and share it with others
                    </Imtxt>
                    <Imtitle> Imbalanced Autumn</Imtitle>
                    <Imtxt>
                    Compensate for your rationality,
                    self-control and meticulousness by finding a little  passion, spontaneity and social frivolity.
                    </Imtxt>
                    <Imtitle> Imbalanced transitions</Imtitle>
                    <Imtxt>
                    Offset over-devotion to relationships with solitude or
                    begin practising self-expression or developing self-resilience.
                    </Imtxt>
                    
                    <Gold primary>
                    If you would like to understand the ways in which you have lost your harmony and work to restore
                    balance then please do look through our free &nbsp;
                     <StyledLink href='https://houseoftheta.com/online-resources/' rel="noopener noreferrer" target="_blank">
                         online resources</StyledLink>, check our up and coming events or get in touch for some &nbsp;
                      <StyledLink href='https://houseoftheta.com/contact/'rel="noopener noreferrer"  target="_blank">
                           1-2-1 coaching</StyledLink> to help navigate your personal journey.  
                    </Gold>

                </div>
                }
                </div>
            </div>
        );
    }
}
export default Pie;